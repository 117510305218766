// ** React
import { createContext, useState } from 'react';

// ** Defaults
const defaultProvider = {
  isMenuShrinked: false,
  setIsMenuShrinked: () => null,
};

const LayoutContext = createContext(defaultProvider);

const LayoutProvider = ({ children }) => {
  // ** States
  const [isMenuShrinked, setIsMenuShrinked] = useState(defaultProvider.isMenuShrinked);

  // ** Handlers
  const handleSetIsMenuShrinked = (value) => setIsMenuShrinked(value);

  const values = {
    isMenuShrinked,
    setIsMenuShrinked: handleSetIsMenuShrinked,
  };

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

// ** Toolkit
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import projects from './projects';

export const store = configureStore({
  reducer: {
    projects,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Sections
export const getSections = createAsyncThunk(
  'projects/getSections',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getSectionsEndpoint, {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        sections: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Section
export const getSection = createAsyncThunk(
  'projects/getSection',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getSectionEndpoint.replace(':id', params.id));
      const data = response.data;

      return {
        section: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Section
export const editSection = createAsyncThunk(
  'projects/editSection',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.patch(apiConfig.editSectionEndpoint.replace(':id', params.id), {
        ...params,
      });
      const data = response.data;

      dispatch(getSections());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Section
export const deleteSection = createAsyncThunk(
  'projects/deleteSection',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(apiConfig.deleteSectionEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(getSections());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Section
export const createSection = createAsyncThunk(
  'projects/createSection',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...other } = params;

    let endpoint;
    if (Number(id) === 0) {
      endpoint = apiConfig.createSectionEndpoint;
    } else {
      endpoint = apiConfig.useSectionTemplateEndpoint.replace(':id', id);
    }

    try {
      const response = await http.post(endpoint, {
        ...other,
      });
      const data = response.data;

      dispatch(getSections());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Section Templates
export const getSectionTemplates = createAsyncThunk(
  'projects/getSectionTemplates',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getSectionTemplatesEndpoint, {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        templates: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Section Template
export const getSectionTemplate = createAsyncThunk(
  'projects/getSectionTemplate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(
        apiConfig.getSectionTemplateEndpoint.replace(':id', params.id),
      );
      const data = response.data;

      return {
        template: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Rate Cards
export const getRateCards = createAsyncThunk(
  'projects/getRateCards',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getRateCardsEndpoint, {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        rateCards: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Rate Card
export const createRateCard = createAsyncThunk(
  'projects/createRateCard',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.post(apiConfig.createRateCardEndpoint, {
        name: params.name,
        order: params.order,
        rates: params.rates,
      });
      const data = response.data;

      dispatch(getRateCards());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Rate Card
export const editRateCard = createAsyncThunk(
  'projects/editRateCard',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.put(apiConfig.editRateCardEndpoint.replace(':id', params.id), {
        name: params.name,
        order: params.order,
        rates: params.rates,
      });
      const data = response.data;

      dispatch(getRateCards());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Rate Card
export const deleteRateCard = createAsyncThunk(
  'projects/deleteRateCard',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(
        apiConfig.deleteRateCardEndpoint.replace(':id', params.id),
      );
      const data = response.data;

      dispatch(getRateCards());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Specialists
export const getSpecialists = createAsyncThunk(
  'projects/getSpecialists',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getSpecialistsEndpoint, {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        specialists: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Specialist
export const createSpecialist = createAsyncThunk(
  'projects/createSpecialist',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.post(apiConfig.createSpecialistEndpoint, {
        name: params.name,
        order: params.order,
      });
      const data = response.data;

      dispatch(getSpecialists());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Specialist
export const editSpecialist = createAsyncThunk(
  'projects/editSpecialist',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.patch(
        apiConfig.editSpecialistEndpoint.replace(':id', params.id),
        {
          name: params.name,
          order: params.order,
        },
      );
      const data = response.data;

      dispatch(getSpecialists());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Specialist
export const deleteSpecialist = createAsyncThunk(
  'projects/deleteSpecialist',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(
        apiConfig.deleteSpecialistEndpoint.replace(':id', params.id),
      );
      const data = response.data;

      dispatch(getSpecialists());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Catalogs
export const getCatalogs = createAsyncThunk(
  'projects/getCatalogs',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getCatalogsEndpoint, {
        params: {
          page_size: 999,
          section: params.section,
        },
      });
      const data = response.data;

      return {
        catalogs: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Catalog
export const createCatalog = createAsyncThunk(
  'projects/createCatalog',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.post(apiConfig.createCatalogEndpoint, {
        ...params,
      });
      const data = response.data;

      dispatch(getCatalogs({ section: params.section || getState().projects.section.id }));

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Catalog
export const editCatalog = createAsyncThunk(
  'projects/editCatalog',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.put(apiConfig.editCatalogEndpoint.replace(':id', params.id), {
        name: params.name,
        section: params.section,
        order: params.order,
        rates: params.rates,
      });
      const data = response.data;

      dispatch(getCatalogs({ section: getState().projects.section.id }));

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Catalog
export const deleteCatalog = createAsyncThunk(
  'projects/deleteCatalog',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.delete(apiConfig.deleteCatalogEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(getCatalogs({ section: getState().projects.section.id }));

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Estimates
export const getEstimates = createAsyncThunk(
  'projects/getEstimates',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getEstimatesEndpoint, {
        params: {
          page_size: 999,
          section: params.section,
        },
      });
      const data = response.data;

      return {
        estimates: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Create Estimate
export const createEstimate = createAsyncThunk(
  'projects/createEstimate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.post(apiConfig.createEstimateEndpoint, {
        ...params,
      });
      const data = response.data;

      dispatch(getEstimates({ section: getState().projects.section.id }));

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Edit Estimate
export const editEstimate = createAsyncThunk(
  'projects/editEstimate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.put(apiConfig.editEstimateEndpoint.replace(':id', params.id), {
        name: params.name,
        section: params.section,
        order: params.order,
        rateCard: params.rateCard,
        data: params.data,
      });
      const data = response.data;

      dispatch(getEstimates({ section: getState().projects.section.id }));

      return {
        estimate: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Delete Estimate
export const deleteEstimate = createAsyncThunk(
  'projects/deleteEstimate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.delete(
        apiConfig.deleteEstimateEndpoint.replace(':id', params.id),
      );
      const data = response.data;

      dispatch(getEstimates({ section: getState().projects.section.id }));

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Estimate
export const getEstimate = createAsyncThunk(
  'projects/getEstimate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getEstimateEndpoint.replace(':id', params.id));
      const data = response.data;

      return {
        estimate: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Public Estimate
export const getPublicEstimate = createAsyncThunk(
  'projects/getPublicEstimate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(
        apiConfig.getPublicEstimateEndpoint.replace(':hashId', params.hashId),
      );
      const data = response.data;

      return {
        estimate: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Publish Estimate
export const publishEstimate = createAsyncThunk(
  'projects/publishEstimate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      await http.post(apiConfig.publishEstimateEndpoint.replace(':id', params.id));

      dispatch(getEstimates({ section: getState().projects.section.id }));

      return {
        public: true,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Hide Estimate
export const hideEstimate = createAsyncThunk(
  'projects/hideEstimate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      await http.post(apiConfig.hideEstimateEndpoint.replace(':id', params.id));

      dispatch(getEstimates({ section: getState().projects.section.id }));

      return {
        public: false,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Send Estimate to Redmine
export const sendEstimateToRedmine = createAsyncThunk(
  'projects/sendEstimateToRedmine',
  async (params, { rejectWithValue }) => {
    try {
      await http.post(apiConfig.sendEstimateToRedmineEndpoint.replace(':id', params.id));

      return {
        success: true,
      };
    } catch (error) {
      return rejectWithValue(error.response.data.detail);
    }
  },
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    isLoadingSections: false,
    isLoadingSection: false,
    isLoadingTemplates: false,
    isLoadingTemplate: false,
    isLoadingRateCards: false,
    isLoadingSpecialists: false,
    isLoadingCatalogs: false,
    isLoadingEstimates: false,
    isLoadingEstimate: false,
    isLoadingPublicEstimate: false,
    sections: [],
    section: {},
    templates: [],
    template: {},
    rateCards: [],
    specialists: [],
    catalogs: [],
    estimates: [],
    estimate: {},
    publicEstimate: {},
    sectionError: false,
    templateError: false,
    rateCardsError: false,
    specialistsError: false,
    catalogsError: false,
    estimatesError: false,
    estimateError: false,
    publicEstimateError: false,
  },
  reducers: {
    resetSection: (state) => {
      state.isLoadingSection = false;
      state.sectionError = false;
      state.section = {};

      state.isLoadingCatalogs = false;
      state.catalogsError = false;
      state.catalogs = [];

      state.isLoadingEstimates = false;
      state.estimatesError = false;
      state.estimates = [];

      state.isLoadingRateCards = false;
      state.rateCardsError = false;
      state.rateCards = [];

      state.isLoadingSpecialists = false;
      state.specialistsError = false;
      state.specialists = [];
    },
    resetTemplate: (state) => {
      state.isLoadingTemplate = false;
      state.templateError = false;
      state.template = {};
    },
    resetEstimate: (state) => {
      state.isLoadingEstimate = false;
      state.estimateError = false;
      state.estimate = {};
    },
    resetPublicEstimate: (state) => {
      state.isLoadingPublicEstimate = false;
      state.publicEstimateError = false;
      state.publicEstimate = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSections.pending, (state) => {
        state.isLoadingSections = true;
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.sections = action.payload.sections;

        state.isLoadingSections = false;
      })
      .addCase(getSections.rejected, (state) => {
        state.isLoadingSections = false;
      });
    builder
      .addCase(getSection.pending, (state) => {
        state.sectionError = false;
        state.isLoadingSection = true;
      })
      .addCase(getSection.fulfilled, (state, action) => {
        state.section = action.payload.section;

        state.isLoadingSection = false;
      })
      .addCase(getSection.rejected, (state, action) => {
        state.section = {};
        state.sectionError = action.payload.detail;

        state.isLoadingSection = false;
      });
    builder
      .addCase(getSectionTemplates.pending, (state) => {
        state.isLoadingTemplates = true;
      })
      .addCase(getSectionTemplates.fulfilled, (state, action) => {
        state.templates = action.payload.templates;

        state.isLoadingTemplates = false;
      })
      .addCase(getSectionTemplates.rejected, (state) => {
        state.isLoadingTemplates = false;
      });
    builder
      .addCase(getSectionTemplate.pending, (state) => {
        state.templateError = false;
        state.isLoadingTemplate = true;
      })
      .addCase(getSectionTemplate.fulfilled, (state, action) => {
        state.template = action.payload.template;

        state.isLoadingTemplate = false;
      })
      .addCase(getSectionTemplate.rejected, (state, action) => {
        state.template = {};
        state.templateError = action.payload.detail;

        state.isLoadingTemplate = false;
      });
    builder
      .addCase(getRateCards.pending, (state) => {
        state.rateCardsError = false;
        state.isLoadingRateCards = true;
      })
      .addCase(getRateCards.fulfilled, (state, action) => {
        state.rateCards = action.payload.rateCards;

        state.isLoadingRateCards = false;
      })
      .addCase(getRateCards.rejected, (state, action) => {
        state.rateCards = [];
        state.rateCardsError = action.payload.detail;

        state.isLoadingRateCards = false;
      });
    builder
      .addCase(getSpecialists.pending, (state) => {
        state.specialistsError = false;
        state.isLoadingSpecialists = true;
      })
      .addCase(getSpecialists.fulfilled, (state, action) => {
        state.specialists = action.payload.specialists;

        state.isLoadingSpecialists = false;
      })
      .addCase(getSpecialists.rejected, (state, action) => {
        state.specialists = [];
        state.specialistsError = action.payload.detail;

        state.isLoadingSpecialists = false;
      });
    builder
      .addCase(getCatalogs.pending, (state) => {
        state.catalogsError = false;
        state.isLoadingCatalogs = true;
      })
      .addCase(getCatalogs.fulfilled, (state, action) => {
        state.catalogs = action.payload.catalogs;

        state.isLoadingCatalogs = false;
      })
      .addCase(getCatalogs.rejected, (state, action) => {
        state.catalogs = [];
        state.catalogsError = action.payload.detail;

        state.isLoadingCatalogs = false;
      });
    builder
      .addCase(getEstimates.pending, (state) => {
        state.estimatesError = false;
        state.isLoadingEstimates = true;
      })
      .addCase(getEstimates.fulfilled, (state, action) => {
        state.estimates = action.payload.estimates;

        state.isLoadingEstimates = false;
      })
      .addCase(getEstimates.rejected, (state, action) => {
        state.estimates = [];
        state.estimatesError = action.payload.detail;

        state.isLoadingEstimates = false;
      });
    builder
      .addCase(getEstimate.pending, (state) => {
        state.estimateError = false;
        state.isLoadingEstimate = true;
      })
      .addCase(getEstimate.fulfilled, (state, action) => {
        state.estimate = action.payload.estimate;

        state.isLoadingEstimate = false;
      })
      .addCase(getEstimate.rejected, (state, action) => {
        state.estimate = {};
        state.estimateError = action.payload.detail;

        state.isLoadingEstimate = false;
      });
    builder.addCase(editEstimate.fulfilled, (state, action) => {
      state.estimate = action.payload.estimate;
    });
    builder
      .addCase(getPublicEstimate.pending, (state) => {
        state.publicEstimateError = false;
        state.isLoadingPublicEstimate = true;
      })
      .addCase(getPublicEstimate.fulfilled, (state, action) => {
        state.publicEstimate = action.payload.estimate;

        state.isLoadingPublicEstimate = false;
      })
      .addCase(getPublicEstimate.rejected, (state, action) => {
        state.publicEstimate = {};
        state.publicEstimateError = action.payload.detail;

        state.isLoadingPublicEstimate = false;
      });
    builder.addCase(publishEstimate.fulfilled, (state) => {
      state.estimate = {
        ...state.estimate,
        public: true,
      };
    });
    builder.addCase(hideEstimate.fulfilled, (state) => {
      state.estimate = {
        ...state.estimate,
        public: false,
      };
    });
  },
});

export const { resetSection, resetTemplate, resetEstimate, resetPublicEstimate } =
  projectsSlice.actions;

export default projectsSlice.reducer;

// ** MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// ** Theme Override
import spacing from './spacing';

const ThemeComponent = ({ children }) => {
  const theme = createTheme({
    ...spacing,
    typography: {
      fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: '#FF6D00',
        contrastText: '#FFFFFF',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
        lightGray: '#D3D3D3',
        darkGray: '#A9A9A9',
        gray: '#808080',
        whiteSmoke: '#F5F5F5',
        redmine: '#628DB6',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#FFFFFF',
            fontSize: 14,
            lineHeight: 1.2,
            fontFamily: ['"Roboto"', 'sans-serif'].join(','),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            fontWeight: 500,
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;

// ** MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
  return (
    <Box flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress color={'primary'} />
    </Box>
  );
};

export default Spinner;

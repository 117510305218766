// ** React
import { Suspense, lazy, useEffect } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

// ** Components
import Spinner from 'src/@core/components/Spinner';

// ** Theme Setup
import ThemeComponent from 'src/@core/theme/ThemeComponent';

// ** Hooks
import { useAuth } from 'src/@core/hooks/useAuth';

// ** Contexts
import { LayoutProvider } from 'src/@core/context/LayoutContext';

// ** http
import { httpInterceptor } from 'src/@core/utils/http';

// ** Utils
import eventBus from 'src/@core/utils/event-bus';

// ** Store
import { store } from 'src/store';
import { Provider } from 'react-redux';

// ** Notistack
import { SnackbarProvider } from 'notistack';

httpInterceptor();

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));
const PublicEstimate = lazy(() => import('./pages/PublicEstimate'));

const App = () => {
  const auth = useAuth();
  const { user, logout } = auth;

  // ** Effects
  useEffect(() => {
    eventBus.subscribe('auth:logout', () => {
      logout();
    });
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {user ? (
          <Route path={'*'} element={<AuthenticatedApp />} />
        ) : (
          <Route path={'*'} element={<UnauthenticatedApp />} />
        )}
        <Route path={'public/:hashId'} element={<PublicEstimate />} />
      </>,
    ),
  );

  return (
    <Provider store={store}>
      <LayoutProvider>
        <ThemeComponent>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Suspense fallback={<Spinner />}>
              <RouterProvider router={router} />
            </Suspense>
          </SnackbarProvider>
        </ThemeComponent>
      </LayoutProvider>
    </Provider>
  );
};

export default App;

const APP_PREFIX = 'estimator';
const getKey = (key) => `${APP_PREFIX}.${key}`;

class LocalStorageService {
  get(key) {
    try {
      const value = window.localStorage.getItem(getKey(key));

      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error('error in local storage get', e);
    }
  }

  set(key, value) {
    try {
      const updatedValue = JSON.stringify(value);
      window.localStorage.setItem(getKey(key), updatedValue);
    } catch (e) {
      console.error('error in local storage set', e);
    }
  }

  remove(key) {
    try {
      return window.localStorage.removeItem(getKey(key));
    } catch (e) {
      console.error('error in local storage remove', e);
    }
  }
}

const localStorageService = new LocalStorageService();

export default localStorageService;

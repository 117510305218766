export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  getSectionsEndpoint: '/sections/',
  getSectionEndpoint: '/sections/:id/',
  editSectionEndpoint: '/sections/:id/',
  deleteSectionEndpoint: '/sections/:id/',
  createSectionEndpoint: '/sections/',
  getSectionTemplatesEndpoint: '/section_templates/',
  getSectionTemplateEndpoint: '/section_templates/:id/',
  useSectionTemplateEndpoint: '/section_templates/:id/use/',
  getRatesEndpoint: '/rates/',
  createRateEndpoint: '/rates/',
  deleteRateEndpoint: '/rates/:id/',
  editRateEndpoint: '/rates/:id/',
  getRateCardsEndpoint: '/rate_cards/',
  createRateCardEndpoint: '/rate_cards/',
  deleteRateCardEndpoint: '/rate_cards/:id/',
  editRateCardEndpoint: '/rate_cards/:id/',
  getSpecialistsEndpoint: '/specialists/',
  createSpecialistEndpoint: '/specialists/',
  deleteSpecialistEndpoint: '/specialists/:id/',
  editSpecialistEndpoint: '/specialists/:id/',
  getCatalogsEndpoint: '/catalogs/',
  createCatalogEndpoint: '/catalogs/',
  deleteCatalogEndpoint: '/catalogs/:id/',
  editCatalogEndpoint: '/catalogs/:id/',
  getEstimatesEndpoint: '/estimates/',
  createEstimateEndpoint: '/estimates/',
  deleteEstimateEndpoint: '/estimates/:id/',
  editEstimateEndpoint: '/estimates/:id/',
  getEstimateEndpoint: '/estimates/:id/',
  getPublicEstimateEndpoint: '/estimates/by_public_id/:hashId/',
  publishEstimateEndpoint: '/estimates/:id/publish/',
  hideEstimateEndpoint: '/estimates/:id/hide/',
  sendEstimateToRedmineEndpoint: '/estimates/:id/send_redmine/',
};
